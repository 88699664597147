import SourceFlowText from "@sourceflow-uk/sourceflowtext"
import SourceFlowImage from "@sourceflow-uk/sourceflowimage"
import {
    Container,
    Row,
    Col,
    Card
} from 'reactstrap';
import styles from './styles.module.scss';
import Link from "next/link"
import SocialLinks from '/components/SocialLinks';
import SimpleForm from '/components/SimpleForm';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import md5 from "md5";
import imageMetaData from "../../.sourceflow/image_metadata.json";

export default function ImageWithTextWithOverlay({
    global,
    pathPrefix,
    customClass = "",
    content,
    buttonTitle,
    buttonLink,
    imageURL,
    imageALT,
    popUpModal_1,
    dialog_content_1,
    popUpModal_1_form_id,
    successPageUrl
}) {
    Fancybox.bind("[data-src]", {
        // Your custom options
    });
    return (
        <section className={`${styles.root} ${customClass}`}>
            <Container>
                <Row className="align-items-center">
                    <Col md={5} className="left-column pe-md-5 mb-5 mb-md-0">
                        <SourceFlowImage
                            src={imageURL ? imageURL : "/site-assets/placeholders/img-placeholder.jpg"}
                            size="1000x"
                            imagesMetaData={imageMetaData}
                            path={`${pathPrefix ? pathPrefix : md5(imageURL)}.image`}
                            alt={imageALT}
                            className="card-img rounded-4"
                        />
                    </Col>
                    <Col md={7} className="right-column ps-md-5">
                        {content ?
                            <div className="mb-4">
                                <SourceFlowText global={global} path={`${pathPrefix}.content`} type="html">
                                    {content}
                                </SourceFlowText>
                            </div> : null}
                        {popUpModal_1 ?
                            <>
                                <button className="border-0 fancyBox primaryBtn no-arrow" data-src={`#dialog-content-1`}>
                                    {buttonTitle}
                                </button>
                            </> : buttonLink ?
                                <div>
                                    <Link href={`${buttonLink}`}>
                                        <a className="primaryBtn no-arrow">
                                            {buttonTitle}
                                        </a>
                                    </Link>
                                </div> : null
                        }
                        {/* // {buttonLink ?
                        //     <div>
                        //         <Link href={`${buttonLink}`}>
                        //             <a className="primaryBtn no-arrow">
                        //                 {buttonTitle}
                        //             </a>
                        //         </Link>
                        //     </div> : null} */}
                        {popUpModal_1_form_id ?
                            <div class="dialog-content" id="dialog-content-1">
                                <div>
                                    {dialog_content_1 ?
                                        <>
                                            <SourceFlowText global={global} path={`${pathPrefix}.dialog-content-1`} type="html">
                                                {dialog_content_1}
                                            </SourceFlowText> <hr></hr>
                                        </> : null}

                                    <SimpleForm
                                        formId={`${popUpModal_1_form_id}`}
                                        successPageUrl={`${successPageUrl}`}
                                    />
                                </div>
                            </div> : null}
                    </Col>
                </Row>
            </Container >
        </section >
    )
}