import { Global, Collection } from '@sourceflow-uk/sourceflow-sdk';
import SeoHead from '../../components/SeoHead';

import MainBanner from '../../components/MainBanner';
import ImageWithTextBanner from '../../components/ImageWithTextBanner';
import ImageWithTextWithOverlay from '../../components/ImageWithTextWithOverlay';
import GetInTouch from '../../components/GetInTouch';
import AccordionColumnBox from '../../components/AccordionColumnBox';
import Link from "next/link"

export default function AuPage({
    global,
    all_contractor_benefits
}) {
    return (
        <>
            <SeoHead
                page_name={`AU | The global house of tech and transformation recruitment | Lorien`}
                canonical_link={`AU`}
            />
            <MainBanner
                global={global}
                pathPrefix={`au.page.main.banner`}
                customClass={``}
                content={
                    <>
                        <h1>Contractors Test</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>
                    </>
                }
                imageURL1={`/site-assets/images/main-banner.jpg`}
                imageALT1={`Main Banner`}
                imageURL2={`/site-assets/images/main-banner-overlay.jpg`}
                imageALT2={`Overlay Main Banner`}
            />
            <AccordionColumnBox
                global={global}
                pathPrefix={`au_page.accordion_column_box`}
                customClass={`pt-5 grayGradientBg`}
                content={
                    <>
                        <h2>Contractor Benefits</h2>
                    </>
                }
                post={all_contractor_benefits}
            />
            <GetInTouch
                global={global}
                pathPrefix={`au_page.get_in_touch`}
                customClass={`py-5`}
                content={
                    <>
                        <h2>Get in ouch!</h2>
                        <p>Unresolved query? Let us help. Get in touch with the Lorien Contractor and Candidate Support Team to find answers quickly, from information on payments to how to submit your timesheets. No matter how big or small the question, no matter how new or experienced you are to the world of contracting, we'll do our best to give you up-to-date, informed, and helpful answers about working through Lorien as a contractor.</p>
                        <br></br>
                        <h5>Our hotlines are:</h5>
                    </>
                }
                // hard_coded_contacts={
                //     <>
                //         <p><strong> Contractor Payments </strong><br></br>
                //             <Link href={`mailto:ask@impellam.com`}>
                //                 <a>
                //                     ask@impellam.com
                //                 </a>
                //             </Link>
                //             <br></br>
                //             <Link href={`tel:0333 3232 450`}>
                //                 <a>
                //                    0333 3232 450
                //                 </a>
                //             </Link></p>

                //         <p> <strong>Contracts Team</strong> <br></br>
                //             <Link href={`mailto:stemcongen@impellam.com`}>
                //                 <a>
                //                     stemcongen@impellam.com
                //                 </a>
                //             </Link>
                //             <br></br>
                //             <Link href={`0203 912 2478`}>
                //                 <a>
                //                     0203 912 2478
                //                 </a>
                //             </Link></p>

                //         <p><strong>Pre-employment Screening (PES)</strong><br></br>
                //             <Link href={`mailto:pes.stem@impellam.com`}>
                //                 <a>
                //                     pes.stem@impellam.com
                //                 </a>
                //             </Link>
                //             <br></br>
                //             <Link href={`tel:0203 912 2462`}>
                //                 <a>
                //                     0203 912 2462
                //                 </a>
                //             </Link></p>
                //         <p><strong>PAYE </strong><br></br>
                //         <Link href={`mailto:ask@impellam.com`}>
                //                 <a>
                //                     ask@impellam.com
                //                 </a>
                //             </Link>
                //             <br></br>
                //             <Link href={`mailto:0333 3232 450`}>
                //                 <a>
                //                     0333 3232 450
                //                 </a>
                //             </Link></p>
                //     </>
                // }
                content_2={``}
                buttonLink={``}
                buttonTitle={``}
                content_3={
                    <>
                        <p>For all other enquiries, please complete our form or reach out to us on <a href="tel:02039109446">02039109446</a>. </p>
                    </>
                }
                formId={`59ab1381-495d-4c89-b5fb-f2f3dc400a3e`}
                successPageUrl={`/au/submission-success/`}
            />
            {/* <ImageWithTextBanner
                global={global}
                pathPrefix={`au_page.image_with_text_banner`}
                customClass={``}
                content={
                    <>
                        <h2>Contractor Portal</h2>
                        <p>The Contractor Portal is your single repository for logging timesheets and raising invoices.</p>
                    </>
                }
                imageURL={`/site-assets/images/contractors-image.jpg`}
                imageALT={`Job Seekers Featured Image`}
                buttonLink={`https://ess.impellam.com/`}
                buttonTitle={`Portal Login`}
                newPageTab={true}
            /> */}
            {/* <ImageWithTextWithOverlay
                global={global}
                pathPrefix={`au_page.image_with_text_with_overlay`}
                customClass={`py-5`}
                content={
                    <>
                        <h2>Ask David</h2>
                        <p>At Lorien, we put people first. Building trust through genuine, honest relationships is one of our core values and underpins everything we do. Ask David is all about resolving problems quickly, helpfully and at the source.</p>
                        <p>As a concept, it’s simple: if you’re a current contractor at Lorien with a question, concern or request, then get in touch using the below contact form and David will respond directly to you himself within 24 hours. With more than 20 years’ experience in recruitment at all levels and operations, David can provide expert guidance and support on almost any recruitment-related subject (and if he can’t, he’ll put you in touch with someone who can).</p>
                    </>
                }
                buttonTitle={`Get in Touch with David`}
                buttonLink={`/contact-us/`}
                imageURL={`/site-assets/images/contractors-image-with-text-with-overlay-bg.jpg`}
                imageALT={`Job Seekers Featured Image`}
                successPageUrl={`/contractors/submission-success`}
                popUpModal_1={true}
                popUpModal_1_form_id={`4460787c-e508-4556-aa34-1d504a3940a2`}
                dialog_content_1={
                    <h5>Ask David</h5>
                }
            /> */}
        </>
    )
}

export async function getStaticProps({ params }) {
    const global = (await Global()).toJson();

    const contractor_benefits = (await Collection('contractor_benefits'))
		.filter(function (post) {
			return (
				post.country_tag?.en.includes("AU")
			);
		});

    const all_contractor_benefits = contractor_benefits.orderBy('_order').getItems();
    return {
        props: {
            global,
            all_contractor_benefits
        }
    };
}

// -- Reference -- //
// https://github.com/sourceflow-uk/components/pkgs/npm/sourceflow-head
// https://github.com/sourceflow-uk/components/pkgs/npm/sourceflowtext