import SourceFlowImage from "@sourceflow-uk/sourceflowimage"
import SourceFlowText from "@sourceflow-uk/sourceflowtext"
import {
    Container,
    Row,
    Col,
    Card,
    Nav,
    NavItem
} from 'reactstrap';
import styles from './styles.module.scss';
import Link from "next/link"
import React, { useState } from 'react';
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';

export default function AccordionColumnBox({
    global,
    pathPrefix,
    customClass = "",
    post,
    content

}) {
    // // Create a state variable to track the open accordion item and its toggle class
    // const [openItem, setOpenItem] = useState(null);

    // const classToggle = (id) => {
    //     // If the clicked item is already open, close it
    //     if (openItem === id) {
    //         setOpenItem(null);
    //     } else {
    //         // Otherwise, open the clicked item
    //         setOpenItem(id);
    //     }
    // };

    const [open, setOpen] = useState('');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    const [activeIndex, setActiveIndex] = useState(null);
    const handleHeaderClick = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };

    return (
        post.length >= 1 && (
            <section className={`${styles.root} ${customClass}`}>
                <Container>
                    {content ?
                        <div className="mb-4">
                            <SourceFlowText global={global} path={`${pathPrefix}.content`} type="html">
                                {content}
                            </SourceFlowText>
                        </div> : null}
                    <Accordion open={open} toggle={toggle} className="row">
                        {post?.map((post, index) => (
                            <AccordionItem key={index} className={`border-0 position-relative bg-transparent mb-4`}>
                                <div className="p-4 wrapper p-4 rounded-4">
                                    <AccordionHeader
                                        targetId={`${index}`}
                                        tag="div"
                                        className=""
                                        onClick={() => handleHeaderClick(`${index}`)}
                                    >
                                        {post?.title}
                                    </AccordionHeader>
                                    {post?.content_preview ? (
                                        <p className={`content-preview ${activeIndex == index ? 'active' : ""}`}>
                                            {post?.content_preview}
                                        </p>
                                    ) : null}
                                    <AccordionBody accordionId={`${index}`}>
                                        {post?.content ?
                                            <div className="" dangerouslySetInnerHTML={{ __html: post?.content }} />
                                            : null}
                                    </AccordionBody>
                                </div>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </Container>
            </section>
        )
    )
}